import 'src/styles/main.scss'
import { Masks } from 'src/modules/masks'

document.addEventListener('DOMContentLoaded', () => {
  const { body, } = document
  const masks = new Masks()
  masks.init()

  const forms: HTMLFormElement[] = [
    body.querySelector('#main__form'),
    body.querySelector('#popup__form'),
  ]

  forms.forEach((form: HTMLFormElement) => {
    const label: string = `
    <label class='main-form-label' id='form-policy'>
      <input type='checkbox' name='policy' class='main-form-checkbox' required>
      <span>
        Даю свое согласие на сбор и обработку моих персональных данных в соответствии с <a href="https://xn--c1aejl5al.xn--p1ai/privacy-policy/" target="_blank">Политикой</a>
      </span>
    </label>`
    if (form) {
      const button: HTMLButtonElement = form.querySelector('[type=submit]')
      if (button) button.insertAdjacentHTML('beforebegin', label)
    }
  })
})
